<template>
  <div>
    <ds-header :title="`${operationType}主页面`"></ds-header>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-row>
        <a-col :span="8">
          <a-form-model-item ref="homePageName" label="页面主题" prop="homePageName">
            <a-input
              :disabled="disabled"
              placeholder="请输入页面主题"
              v-model="form.homePageName"
              :maxLength="10"
              @blur="
          () => {
            $refs.homePageName.onFieldBlur();
          }
        "
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item ref="homePagePenetrate" label="透出端" prop="homePagePenetrate">
            <a-radio-group :disabled="disabled" @change="penetrateChange" v-model="form.homePagePenetrate">
              <a-radio :value="1">
                App
              </a-radio>
              <a-radio :value="2">
                小程序
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <a-form-model-item label="页面数量" prop="homePageSize">
            <a-select :disabled="disabled" v-model="form.homePageSize" @change="handlePageSizeChange" placeholder="请选择页面数量">
              <a-select-option :value="1" :disabled="form.homePagePenetrate === 2">
                1
              </a-select-option>
              <a-select-option :value="2">
                2
              </a-select-option>
              <a-select-option :value="3">
                3
              </a-select-option>
              <a-select-option :value="4" :disabled="form.homePagePenetrate === 1">
                4
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item ref="projectId" label="所属项目" prop="projectId">
            <a-tree-select
              :disabled="disabled"
              v-model="form.projectId"
              show-search
              style="width: 100%"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              placeholder="请选择所属项目"
              allow-clear
              tree-default-expand-all
            >
              <a-tree-select-node :disabled="item.disabled" :value="item.id" :title="item.project_name" v-for="item in projects" :key="item.id">
                <a-tree-select-node :key="arr.id" :value="arr.id" :title="arr.project_name" v-for="arr in item.child">
                </a-tree-select-node>
              </a-tree-select-node>
            </a-tree-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <div v-for="(item, index) in pageConfigList" :key="index">
        <div class="page-class">
          <div class="page-title">
            {{ `页面${index + 1}` }}
          </div>
          <PageContent
            :disabled="disabled"
            :pageConfigsLength="pageConfigList.length"
            ref="configForm"
            :configForm="item"
            :currentIndex="index"
            :nativePages="nativePages"
            :homePagePenetrate="form.homePagePenetrate"
            @submitChildData="submitChildData"
          />
        </div>
      </div>
      <a-row>
        <a-col :span="8">
          <a-form-model-item label="状态" :required="true">
            <a-radio-group :disabled="disabled" v-model="form.status">
              <a-radio :value="1">启用</a-radio>
              <a-radio :value="0">禁用</a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="onSubmit" v-show="!disabled">
          保存
        </a-button>
        <a-button style="margin-left: 10px;" @click="cancel">
          取消
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import {addMainPageConfig, getAllNativePage, getMainPageConfigDetail, updateMainPageConfig} from "@/api/mainPageConfig/mainPageConfig";
import PageContent from '../components/PageContent.vue'
import {pageConfig} from "./index";
import {getProjects} from "@/api/common";
export default {
  components: {
    PageContent
  },
  data() {
    return {
      // 是否禁用
      disabled: false,
      // 这是子组件是否通过校验的list，如果其中有false，则没有通过校验
      flagList: [],
      pageConfig,
      operationType: '新增',
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      // 原生页面
      nativePages: [],
      // 所有项目
      projects: [],
      form: {
        // 页面主题
        homePageName: '',
        // 透出端 1: app 2: 小程序
        homePagePenetrate: 1,
        // 页面数量
        homePageSize: undefined,
        // 所属项目
        projectId: undefined,
        // 状态
        status: 1
      },
      rules: {
        homePageName: [
          {required: true, message: '请输入页面主题', trigger: 'blur'},
        ],
        homePagePenetrate: [
          { required: true, message: '请选择透出端', trigger: 'change' }
        ],
        homePageSize: [
          { required: true, message: '请选择页面数量', trigger: 'change' }
        ],
        projectId: [
          { required: true, message: '请选择所属项目', trigger: 'change' }
        ]
      },
      // 页面配置
      pageConfigList: [],

    }
  },
  async created() {
    const type = this.$route.query.type;
    const id = this.$route.query.id
    if(type === 'view') {
      this.disabled = true
      this.operationType = '查看'
      await this.getDetail(id);
    } else if (type === 'edit') {
      this.operationType = '编辑'
      await this.getDetail(id)
    } else {
      this.operationType = '新增'
    }
    // 查询项目
    await this.getProjectList()
    // 查询原声页面
    await this.getNativePage()
  },
  methods: {
    /**
     * 透出端change事件
     */
    penetrateChange(e) {
      this.form.homePageSize = undefined
      this.pageConfigList = []
      this.getNativePage()
    },
    /**
     * 查询有子项目的项目列表
     */
    async getProjectList() {
      const res = await getProjects()
      const { code, data, msg } = res
      if(code === '200') {
        this.projects = data && data.length && data.map(item => {
          return {
            ...item,
            disabled: !(item.child === null)
          }
        })
      } else {
        this.$message.error(msg)
      }
    },
    /**
     * 根据id查询详情
     */
    async getDetail(id){
      const res = await getMainPageConfigDetail(id)
      const { code, data, msg } = res
      if (code === '200') {
        let newData = JSON.parse(JSON.stringify(data))
        this.pageConfigList = newData.homePageDetailList && newData.homePageDetailList.map(item => {
          return {
            ...item,
            disabled: item.pageDetail === '17' || item.pageDetail === '20'
          }
        })
        // 页面配置
        // this.pageConfigList = newData.homePageDetailList
        console.log(this.pageConfigList, '$$$$$')
        this.form = newData
      } else {
        this.$message.error(msg)
      }
    },
    /**
     * 查询原生页面
     */
    async getNativePage() {
      console.log(this.form.homePagePenetrate, '@@')
      const res = await getAllNativePage(this.form.homePagePenetrate);
      const { code, data, msg } = res
      if (code === '200') {
        this.nativePages = data
      } else {
        this.$message.error(msg)
      }
    },
    /**
     * 页面数量change事件
     */
    handlePageSizeChange(val) {
        this.pageConfigList.length = 0
        for(let i = 0; i < val; i++) {
          let config = JSON.parse(JSON.stringify(this.pageConfig))
          if(i === 0) {
            config.pageDetailType = 0
            config.pageDetail = this.nativePages.find(item => item.serviceNumber === '1017').id
            config.disabled = true
          }
          if(this.form.homePagePenetrate === 2 && ((this.form.homePageSize - 1) === i)) {
            config.pageDetailType = 0
            config.pageDetail = this.nativePages.find(item => item.serviceNumber === '1020').id
            config.disabled = true
          }
          this.pageConfigList.push(config)
        }
        console.log(this.pageConfigList, 'pageList')
    },
    /**
     * 从子组件传过来的数据
     */
    submitChildData(data) {
      this.flagList.push(data)
    },
    /**
     * 保存数据
     */
    async onSubmit() {
      console.log(this.pageConfigList, '###')
      // 重置子组件的校验
      this.flagList.length = 0
      const that = this
      const valid = await this.$refs.ruleForm.validate().catch(e => e);
      new Promise(function(resolve, reject) {
        if(that.$refs.configForm && that.$refs.configForm.length) {
          that.$refs.configForm.forEach(async (item) => {
            console.log(item, '这是子组件')
            await item.submit()
          })
          if(valid && that.flagList.every(item => item === true)) {
            // 校验原生页面有没有重复的
            const nativePages = that.pageConfigList.filter(item => item.pageDetailType === 0)
            let set = new Set();
            nativePages.forEach(item => {
              set.add(item.pageDetail)
            })
            if(set.size !== nativePages.length) {
              that.$message.warning('原生页面有重复项！')
              return
            } else{
              // 提交数据
              that.submitMainPageConfig()
            }
          } else {
            reject()
          }
        } else {

        }
      })
    },
    /**
     * 去添加数据
     */
    async submitMainPageConfig() {
      let res = {}
      if(this.pageConfigList && this.pageConfigList.length) {
        this.pageConfigList = this.pageConfigList.map((item, index) => {
          return {
            ...item,
            pageSort: index + 1
          }
        })
      }
      const data = {
        ...this.form,
        pageDetailList: this.pageConfigList
      }

      if (this.$route.query.type === 'add') {
        res = await addMainPageConfig(data);
      } else {
        res = await updateMainPageConfig(data)
      }
      const { code, msg } = res
      if (code === '200') {
        this.$message.success('操作成功！')
        this.$router.back()
      } else {
        this.$message.error(msg)
      }
    },
    /**
     * 取消
     */
    cancel() {
      this.$router.back()
    }
  },
}
</script>

<style scoped lang="scss">
.page-class {
  border: #9c9c9c solid 1px;
  margin-bottom: 20px;
  .page-title {
    background: #eeeeee;
    border-bottom:  #9c9c9c solid 1px;
    padding: 10px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #000000;
  }
}
</style>
