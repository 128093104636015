export const pageConfig = {
    // 页面名称
    pageDetailName: '',
    // 页面类型（0：原生页面  1：H5页面  2：自定义页面）
    pageDetailType: 0,
    // 页面内容（内容根据页面类型区分，为0时存的为原生页面ID，为1存的为H5链接，为2存的富文本）
    pageDetail: '',
    // 选中icon路径
    checkedIconUrl: '',
    // 未选中icon路径
    uncheckedIconUrl: '',
    // 页面排序，当前为第几个页面的配置
    pageSort: ''
}
