<template>
  <div>
    <a-form-model
      ref="configForm"
      :model="configForm"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-row>
        <a-col :span="8">
          <a-form-model-item label="页面名称" prop="pageDetailName">
            <a-input placeholder="请输入页面名称" :disabled="disabled" v-model.trim="configForm.pageDetailName" :maxLength="5" />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="页面类型" prop="pageDetailType">
            <a-radio-group
              v-model="configForm.pageDetailType"
              :disabled="disabled || configForm.disabled"
              @change="handlePageTypeChange"
            >
              <a-radio :value="0">原生页面</a-radio>
              <a-radio :value="1" :disabled="homePagePenetrate === 2">H5页面</a-radio>
              <a-radio :value="2">自定义页面</a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <a-form-model-item v-if="configForm.pageDetailType === 0" :required="true" label="选择页面" prop="pageDetail">
            <a-select
              :disabled="disabled || configForm.disabled"
              v-model="configForm.pageDetail"
              placeholder="请选择页面"
            >
              <a-select-option v-for="item in nativePages" :key="item.id">
                {{ item.serviceName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="configForm.pageDetailType === 1" :required="true" label="页面链接" prop="pageDetail">
            <a-input :disabled="disabled" v-model.trim="configForm.pageDetail" :max="500" placeholder="请输入页面链接" />
          </a-form-model-item>
          <a-form-model-item v-if="configForm.pageDetailType === 2" :required="true" label="页面内容" prop="pageDetail">
            <div class="content">
              <div :id="`editer${currentIndex}`"></div>
            </div>
          </a-form-model-item>
        </a-col>
        <a-col :span="8" :style="{ display: 'flex' }">
          <a-form-model-item label="icon图片" :labelCol="{ span: 10, offset: 2 }" :wrapperCol="{ span: 10 }" prop="checkedIconUrl">
            <div :style="{ display: 'flex' }">
              <div class="clearfix">
                <a-upload
                  :disabled="disabled"
                  :action="IMG_API + '/oss/files'"
                  list-type="picture-card"
                  :file-list="checkedFileList"
                  @preview="handlePreview"
                  @change="handleChange"
                >
                  <div v-if="checkedFileList.length < 1">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">
                      {{ `上传/${homePagePenetrate === 1 && currentIndex === 1 && pageConfigsLength  === 3 ? 'Icon图': '选中态'}` }}
                    </div>
                  </div>
                </a-upload>
                <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                  <img alt="example" style="width: 100%" :src="previewImage" />
                </a-modal>
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item :style="{ marginLeft: '200px' }" prop="uncheckedIconUrl" :wrapperCol="{ span: 14 }">
            <div class="clearfix">
              <a-upload
                :disabled="disabled"
                :action="IMG_API + '/oss/files'"
                list-type="picture-card"
                :file-list="uncheckedFileList"
                @preview="handlePreview"
                @change="handleUncheckedChange"
              >
                <div v-if="uncheckedFileList.length < 1">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">
                    {{ `上传/${homePagePenetrate === 1 && currentIndex === 1 && pageConfigsLength  === 3 ? '背景图': '/默认态'}` }}
                  </div>
                </div>
              </a-upload>
              <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import {IMG_API} from "@/config";
import wangEditor from "wangeditor";
import {left} from "core-js/internals/array-reduce";
export default {
  computed: {
    IMG_API() {
      return IMG_API
    }
  },
  props: ['configForm', 'disabled', 'currentIndex', 'nativePages', 'homePagePenetrate', 'pageConfigsLength'],
  watch: {
    'configForm': {
      handler(val) {
        console.log(val, 'val')
        if(val && val.checkedIconUrl) {
          this.checkedFileList = [{
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: val.checkedIconUrl
          }]
        } else {
          this.checkedFileList = []
        }
        if(val && val.uncheckedIconUrl) {
          this.uncheckedFileList = [{
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: val.uncheckedIconUrl
          }]
        } else {
          this.uncheckedFileList = []
        }
      },
      immediate: true,
      deep: true
    },
    'configForm.pageDetailType': {
      handler(val) {
        if(val === 2) {
          this.$nextTick(async () => {
            await this.createEditor()
            this.editor.txt.html(this.configForm.pageDetail);
            if(this.disabled) {
              this.editor.disable()
            }
          })
        }
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    let validatePageDetail = (rule, value, callback) => {
      if (!value && this.configForm.pageDetailType === 0) {
        callback(new Error('请选择页面'));
      } else if (!value && this.configForm.pageDetailType === 1) {
        callback(new Error("请输入h5地址"));
      } else if(!value && this.configForm.pageDetailType === 2) {
        callback(new Error("请输入自定义内容"));
      } else {
        callback();
      }
    };
    return {
      editor: {},
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      checkedFileList:[],
      // 预览的图片
      previewImage: '',
      // 是否展示
      previewVisible: false,
      uncheckedFileList:[],
      rules: {
        pageDetailName: [
          { required: true, message: '请输入页面名称', trigger: 'blur' }
        ],
        pageDetailType: [
          { required: true, message: '请选择页面类型', trigger: 'blur' }
        ],
        pageDetail: [
          { validator: validatePageDetail, trigger: 'blur' }
        ],
        checkedIconUrl: [
          { required: true, message: '请选择选中状态的icon', trigger: 'blur' }
        ],
        uncheckedIconUrl: [
          { required: true, message: '请选择未选中状态的icon', trigger: ['change', 'blur'] }
        ]
      }
    }
  },
  methods: {
    left,
    /**
     * 选中状态的upload
     */
    handleChange({ fileList }) {
      this.checkedFileList = fileList;
      if(fileList && fileList.length && fileList[0].status === 'done') {
        this.configForm.checkedIconUrl = fileList[0].response.redirect_uri
      } else {
        this.configForm.checkedIconUrl = ''
      }
    },
    /**
     * 默认状态下的icon
     */
    handleUncheckedChange({ fileList }) {
      this.uncheckedFileList = fileList;
      if(fileList && fileList.length && fileList[0].status === 'done') {
        this.configForm.uncheckedIconUrl = fileList[0].response.redirect_uri
      } else {
        this.configForm.uncheckedIconUrl = ''
      }
    },
    /**
     * 预览选中状态的icon
     */
    async handlePreview(file) {
      // if (!file.url && !file.preview) {
      //   file.preview = await getBase64(file.originFileObj);
      // }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    /**
     * 取消预览
     */
    handleCancel() {
      this.previewVisible = false
    },
    /**
     * 校验并提交数据
     */
    submit() {
      this.$refs.configForm.validate(valid => {
        if (valid) {
          this.$emit('submitChildData', true)
        } else {
          this.$emit('submitChildData', false)
        }
      });
    },
    /**
     * 页面类型change事件
     */
    handlePageTypeChange(e) {
      this.configForm.pageDetail = ''
    },
    // 创建富文本
    async createEditor() {
      const _this = this;
      const editor = new wangEditor(`#editer${this.currentIndex}`);
      // 配置 onchange 回调函数，将数据同步到 vue 中
      editor.config.onchange = (newHtml) => {
        this.configForm.pageDetail = newHtml;
      };
      editor.config.menus = [
        "fontName",
        "fontSize",
        "foreColor",
        "backColor",
        "underline",
        "italic",
        "bold",
        "justify",
        "splitLine",
        "undo",
        "redo",
        "list",
        "table",
        "image",
        "video",
      ];
      editor.config.uploadVideoServer = this.IMG_API + "/oss/files";
      editor.config.uploadImgServer = this.IMG_API + "/oss/files";
      editor.config.uploadImgShowBase64 = false;
      editor.config.showLinkImg = false;
      editor.config.showLinkVideo = false;
      editor.config.uploadVideoMaxSize = 5 * 1024 * 1024;
      editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
      editor.config.uploadImgAccept = ["jpg", "jpeg", "png", "gif"];
      editor.config.uploadImgMaxLength = 1; // 一次最多上传 1 个图
      // editor.config.uploadVideoAccept = ['mp4']
      editor.config.uploadFileName = "file";
      editor.config.uploadVideoName = "file";
      editor.config.uploadVideoTimeout = 60000;
      editor.config.uploadImgTimeout = 60000;
      editor.config.uploadVideoHooks = {
        // 上传视频之前
        before: function (xhr, editor, files) {
          console.log(xhr);
          console.log(editor);
          console.log(files);
        },
        // 视频上传并返回了结果，视频插入已成功
        success: function (xhr) {
          // this.$message.success('上传视频成功')
        },
        // 视频上传并返回了结果，但视频插入时出错了
        fail: function (xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频出错，一般为 http 请求的错误
        error: function (xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频超时
        timeout: function (xhr) {
          _this.$message.error("上传请求超时");
        },
        // 视频上传并返回了结果，想要自己把视频插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
        customInsert: function (insertVideoFn, result) {
          let videoHTML =
            '&nbsp;<video poster="' +
            result.redirect_uri +
            '?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto" src="' +
            result.redirect_uri +
            '" controls style="max-width:100%"></video>&nbsp;';
          editor.cmd.do("insertHTML", videoHTML);
          // result 即服务端返回的接口
          // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
          // insertVideoFn(result.redirect_uri)
        },
      };
      editor.config.uploadImgHooks = {
        // 上传视频之前
        before: function (xhr) {},
        // 视频上传并返回了结果，视频插入已成功
        success: function (xhr) {
          // this.$message.success('上传视频成功')
        },
        // 视频上传并返回了结果，但视频插入时出错了
        fail: function (xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频出错，一般为 http 请求的错误
        error: function (xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频超时
        timeout: function (xhr) {
          _this.$message.error("上传请求超时");
        },
        // 视频上传并返回了结果，想要自己把视频插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
        customInsert: function (insertVideoFn, result) {
          // result 即服务端返回的接口
          // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
          insertVideoFn(result.redirect_uri);
        },
      };
      editor.config.customAlert = function (s, t) {
        switch (t) {
          case "success":
            _this.$message.success(s);
            break;
          case "info":
            _this.$message.info(s);
            break;
          case "warning":
            _this.$message.warning(s);
            break;
          case "error":
            _this.$message.error(s);
            break;
          default:
            _this.$message.info(s);
            break;
        }
      };
      editor.config.placeholder = "请输入";
      // 创建编辑器
      editor.create();
      this.editor = editor;
      // 查看的时候禁用富文本
      if (this.disabled) {
        this.editor.disable();
      } else {
        this.editor.enable();
      }
    },
  }
}
</script>

<style scoped lang="scss">

</style>
